import React, { Component } from 'react';

import Title from '../Title';
import Button from '../Button';
import Heading from '../Heading';

import TypeAndLanguage from './TypeAndLanguage';

import languageManager from '../../translations/index';

import './Jaarvergunning.css';
import api, { BASE_URL } from '../../utils/api';
import PersonalDetails from './PersonalDetails';
import Errors from '../Errors';
import withTranslations from '../../utils/translations/withTranslations';

export const CALENDAR_YEAR = 2024; // Needs to be hard coded...

const federations = [
  { value: 'mclb', label: 'MCLB' },
  { value: 'vjmo', label: 'VJMO' }
];

class YearLicense extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isValid: true,
      isBusy: false,
      isSuccess: false,
      email: undefined,
      language: undefined,
      federation: undefined,
      type: undefined,
      token: undefined,
      errors: undefined
    };
  }

  componentDidMount() {
    languageManager.setLanguage('nl');
  }

  requestYearLicense({ body }) {
    this.setState({
      isBusy: true
    });

    api
      .post('/year_license', {
        body: { ...body, language: this.state.language, type: this.state.type }
      })
      .then(res => {
        this.setState({
          isBusy: false,
          isSuccess: true,
          email: body.email,
          errors: undefined
        });
      })
      .catch(({ data: errors }) => {
        this.setState({
          isBusy: false,
          errors
        });
      });
  }

  onRecaptcha(token) {
    this.setState({ token });
  }

  setLanguage(language) {
    languageManager.setLanguage(language);

    this.setState({ language }, () => console.log(this.state));
  }

  setType(type) {
    this.setState({ type });
  }

  getTermsLink() {
    const { federation, language } = this.state;

    return `${BASE_URL}/year_license/terms/${language || 'nl'}/${federation || 'mclb'}`;
  }

  setFederation(event) {
    const federation = event.target.value;

    this.setState({ federation });
  }

  render() {
    const { translate } = this.props;
    const { email, language, type, errors, isSuccess } = this.state;

    if (false) {
      return (
        <div className="Jaarvergunning">
          <Title name="Aanvraag jaarvergunning" />
          <Heading
            title={`Aanvraag jaarvergunning ${CALENDAR_YEAR}`}
            subtitle="Ook heraanvragen!"
          />

          <div className="container Jaarvergunning__Content">
            <div className="col-lg-12">
              <h2>Aanvraag jaarvergunning {CALENDAR_YEAR} kan vanaf 1 november.</h2>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Title name="Aanvraag jaarvergunning" />
        <Heading title={`Aanvraag jaarvergunning ${CALENDAR_YEAR}`} subtitle="Ook heraanvragen!" />

        <div className="container Jaarvergunning__Content">
          {isSuccess && (
            <div>
              <h2>{translate('success.title')}</h2>
              <p>
                {translate('success.text')} {email}
              </p>
              <p>{translate('success.checkSpam')}</p>
            </div>
          )}

          <Errors errors={errors} />

          {!isSuccess && (
            <>
              <TypeAndLanguage
                language={language}
                setLanguage={lang => this.setLanguage(lang)}
                type={type}
                setType={type => this.setType(type)}
              />

              {language && type && (
                <>
                  <div style={{ marginTop: '6rem' }}></div>
                  <PersonalDetails
                    termsLink={this.getTermsLink()}
                    federations={federations}
                    setState={(...props) => this.setState(...props)}
                    requestYearLicense={(...props) => this.requestYearLicense(...props)}
                    setFederation={(...props) => this.setFederation(...props)}
                    onRecaptcha={(...props) => this.onRecaptcha(...props)}
                    isBusy={this.state.isBusy}
                    isValid={this.state.isValid}
                  />

                  <Errors errors={errors} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslations(YearLicense);
